import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import storeData from './StoreData.json'; // Import the JSON data
import StoreTable from './StoreTable'; // Import the StoreTable component

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if user is already logged in
        const storedUser = localStorage.getItem('username');
        if (storedUser) {
            setUsername(storedUser);
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        // Here you can add your authentication logic
        if (username && password) {
            // Store username in local storage
            localStorage.setItem('username', username);
            setIsLoggedIn(true);
        }
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername('');
        setPassword('');
        // Remove username from local storage
        localStorage.removeItem('username');
    };

    return (
        <div className="container">
            <h1 className="mt-4">Store Data Application</h1>
            {isLoggedIn ? (
                <div>
                    <h2>Welcome, {username}!</h2>
                    <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
                    <StoreTable /> {/* Render the StoreTable component after login */}
                </div>
            ) : (
                <form onSubmit={handleLogin}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
            )}
        </div>
    );
}

export default App;